import React, { useState, useEffect } from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Input, InputType } from 'components/input/input'
import { Language } from 'generated/sdk'

type SearchInputProps = {
	className?: string
	value?: string
	placeholder?: string
	autoComplete?: 'off' | 'on'
	compressed?: boolean
	hasError?: boolean
	errorMessage?: string
	handleSearch?: (searchTerm: string) => void
}

export const INPUT_TEXT = {
	search: {
		[Language.Pt]: 'Pesquisar',
		[Language.En]: 'Search',
	},
}

const SearchInputComponent = ({
	className,
	value,
	placeholder,
	autoComplete,
	compressed,
	hasError,
	errorMessage,
	handleSearch,
	...props
}: SearchInputProps) => {
	const { language } = useLocationContext()
	const [searchTerm, setSearchTerm] = useState('')

	useEffect(() => {
		setSearchTerm(value || '')
	}, [value])

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		handleSearch && handleSearch(searchTerm)
	}

	const input = {
		type: InputType.input,
		config: {
			type: 'text',
			id: 'search-input-search',
			placeholder: placeholder ? placeholder : INPUT_TEXT.search[language],
			name: 'search',
		},
		value: searchTerm || '',
		showMagnifier: true,
	}

	return (
		<form className={className} autoComplete={autoComplete} onSubmit={handleSubmit} {...props}>
			<Input
				{...input}
				compressed={compressed}
				hasError={hasError}
				errorMessage={errorMessage}
				onChange={e => setSearchTerm(e.target.value)}
				noMargin
			/>
		</form>
	)
}

export const SearchInput = React.memo(SearchInputComponent)
