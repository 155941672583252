import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { InputConfig } from 'components/input/input'
import { borderColor, InputError } from 'components/input-text/input-text'
import { mediaBreakpointUpSm } from 'theme/breakpoints'
import { transition } from 'theme/utils'

type InputCheckboxProps = {
	config: InputConfig
	hasError?: boolean
	errorMessage?: string
	clearCheckbox?: boolean
	register?: (ref: HTMLInputElement) => void
}

const InputCheckboxWrapper = styled.label`
	position: relative;
	padding: 0 0 0 24px;
	margin: 0;

	${mediaBreakpointUpSm} {
		padding: 0 0 0 20px;
	}
`

const InputCheckboxIcon = styled.svg`
	position: absolute;
	fill: none;
	stroke: ${props => props.theme.color.primary};
	stroke-width: 2px;
`

const InputCheckboxMark = styled.div<{ checked: boolean; hasError: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 16px;
	height: 16px;
	background: ${props => props.theme.background};
	border-color: ${props => borderColor(props, props.hasError)};
	border-style: solid;
	border-width: 1px;
	border-radius: 2px;
	transition: ${transition('box-shadow')}, ${transition('border')};

	&:hover {
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
	}

	${mediaBreakpointUpSm} {
		top: 2px;
	}

	input:focus + & {
		border-color: ${props => props.theme.color.primary};
	}

	input:disabled + & {
		border-color: ${props => borderColor(props, false, true)};

		${InputCheckboxIcon} {
			stroke: ${props => props.theme.colors.veryLightPinkThree};
		}
	}

	${InputCheckboxIcon} {
		visibility: ${props => (props.checked ? 'visible' : 'hidden')};
	}
`

const InputCheckboxLabel = styled.div`
	p {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 22px;
		color: ${props => props.theme.text.primary};
	}

	a {
		color: ${props => props.theme.color.primary};
		text-decoration: underline;
		transition: ${transition('color')};

		&:hover,
		&:focus,
		&:active {
			color: ${props => props.theme.color.secondary};
		}
	}
`

export const InputCheckbox = ({
	config,
	hasError = false,
	errorMessage,
	clearCheckbox,
	register,
}: InputCheckboxProps) => {
	const [isChecked, setIsChecked] = useState(false)
	const { label, ...inputConfig } = config

	useEffect(() => {
		clearCheckbox && setIsChecked(false)
	}, [clearCheckbox])

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(event.target.checked)
	}

	return (
		<>
			<InputCheckboxWrapper {...(config.id && { htmlFor: config.id })}>
				<input
					className="sr-only"
					{...inputConfig}
					defaultChecked={isChecked}
					onChange={handleCheckboxChange}
					ref={register}
				/>
				<InputCheckboxMark checked={isChecked} hasError={hasError}>
					<InputCheckboxIcon viewBox="0 0 24 24">
						<polyline points="20 6 9 17 4 12" />
					</InputCheckboxIcon>
				</InputCheckboxMark>
				<InputCheckboxLabel dangerouslySetInnerHTML={{ __html: config.label || '' }} />
			</InputCheckboxWrapper>
			{errorMessage && <InputError>{errorMessage}</InputError>}
		</>
	)
}
