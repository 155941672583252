import React from 'react'
import styled, { css, ThemeProps, DefaultTheme } from 'styled-components'
import { FieldElement } from 'react-hook-form'
import { InputConfig } from 'components/input/input'
import { SvgMagnifier } from 'components/svgs/svg-magnifier'
import { rgbaWithHex, transition } from 'theme/utils'

type InputTextProps = {
	config: InputConfig
	value?: string
	compressed?: boolean
	showMagnifier?: boolean
	hasError?: boolean
	errorMessage?: string
	register?: (ref: FieldElement) => void
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export const borderColor = (props: ThemeProps<DefaultTheme>, hasError = false, disabled = false) => {
	const color = hasError
		? props.theme.colors.dullRed
		: disabled
		? props.theme.colors.veryLightPinkFour
		: props.theme.isDark
		? 'transparent'
		: props.theme.colors.veryLightPinkTwo

	return color
}

export const inputStyle = (withIcon = false, compressed = false, hasError = false) => {
	const inputPadding =
		withIcon && compressed
			? '7px 8px 7px 40px'
			: !withIcon && compressed
			? '7px'
			: withIcon
			? '9px 8px 9px 35px'
			: '9px'

	return css`
		box-sizing: border-box;
		display: block;
		width: 100%;
		max-height: ${compressed ? '32px' : '36px'};
		padding: ${inputPadding};
		font-family: ${props => props.theme.fonts.primary};
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
		color: ${props => props.theme.text.primary};
		${props => props.theme.isDark && rgbaWithHex(props.theme.text.primary, 0.2, 'background-color')}
		border-color: ${props => borderColor(props, hasError)};
		border-style: solid;
		border-width: 1px;
		border-radius: 2px;
		appearance: none;
		transition: ${transition('all')};

		&::placeholder {
			position: relative;
			top: 2px;
			${props => rgbaWithHex(props.theme.text.primary, 0.7)}
			opacity: 1;
		}

		&::-ms-input-placeholder {
			position: relative;
			top: 2px;
			${props => rgbaWithHex(props.theme.text.primary, 0.7)}
		}

		&:-ms-input-placeholder {
			position: relative;
			top: 2px;
			${props => rgbaWithHex(props.theme.text.primary, 0.7)}
		}

		&:hover {
			box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
		}

		&:focus {
			border-color: ${props => props.theme.color.primary};
			outline: none;
		}

		&[type='search'] {
			&::-webkit-search-cancel-button {
				max-width: 16px;
				max-height: 16px;
				margin-right: 6px;
			}
		}

		&:disabled {
			color: ${props => props.theme.colors.veryLightPinkThree};
			background-color: ${props => props.theme.colors.white};
			border-color: ${props => borderColor(props, false, true)};
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus {
			/* the autofill default background doesn't match with the Porto. blue */
			${props => props.theme.isDark && `-webkit-text-fill-color: ${props.theme.colors.white};`}
			${props => props.theme.isDark && `box-shadow: 0 0 0 1000px ${props.theme.colors.greyBlue} inset;`}
		}
	`
}

export const InputIconWrapper = styled.div`
	position: relative;

	svg {
		position: absolute;
		top: calc(50% + -1px);
		left: 8px;
		z-index: 1;
		pointer-events: none;
		transform: translateY(-50%);

		path {
			fill: ${props => props.theme.color.primary};
		}
	}
`

export const InputLabel = styled.label<{ disabled?: boolean }>`
	display: inline-block;
	margin: 0;
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	color: ${props => props.theme.text.primary};
	pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

	b {
		display: inline-block;
		margin-left: 4px;
		color: ${props => props.theme.color.primary};
	}
`

export const InputElement = styled.input<{ withIcon: boolean; compressed: boolean; hasError: boolean; ref: any }>`
	${props => inputStyle(props.withIcon, props.compressed, props.hasError)}
`

export const InputError = styled.span`
	display: inline-block;
	margin: 4px 0 0;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	color: ${props => props.theme.colors.dullRed};
`

export const InputText = ({
	config,
	value,
	compressed = false,
	showMagnifier = false,
	hasError = false,
	errorMessage,
	register,
	onChange,
	onBlur,
}: InputTextProps) => {
	let inputElement = null
	const { label, required, ...inputConfig } = config
	const element = (
		<InputElement
			{...inputConfig}
			value={value}
			withIcon={showMagnifier}
			compressed={compressed}
			ref={register}
			hasError={hasError}
			onChange={onChange}
			onBlur={onBlur}
		/>
	)

	if (showMagnifier) {
		inputElement = (
			<>
				{config && config.label && (
					<InputLabel {...(config.id && { htmlFor: config.id })}>
						{config.label}
						{required && <b>*</b>}
					</InputLabel>
				)}
				<InputIconWrapper>
					<SvgMagnifier suffix="-input" />
					{element}
				</InputIconWrapper>
				{errorMessage && <InputError>{errorMessage}</InputError>}
			</>
		)
	} else {
		inputElement = (
			<>
				{config && config.label && (
					<InputLabel {...(config.id && { htmlFor: config.id })}>
						{config.label}
						{required && <b>*</b>}
					</InputLabel>
				)}
				{element}
				{errorMessage && <InputError>{errorMessage}</InputError>}
			</>
		)
	}

	return inputElement
}
