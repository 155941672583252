import React from 'react'
import { useLocationContext } from 'components/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Pesquisa',
	[Language.En]: 'Search',
}

export const SvgMagnifier: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-logo' + suffix

	return (
		<svg width={24} height={24} role="img" aria-labelledby={`${idPrefix}-title`} {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<path
				fill="#FFF"
				d="M7.242 14.174c2.021 1.947 5.299 1.947 7.32 0a4.858 4.858 0 000-7.051c-2.021-1.948-5.299-1.948-7.32 0a4.858 4.858 0 000 7.051zm8.54-8.227c2.593 2.498 2.691 6.488.296 9.1l3.67 3.534c.324.313.334.83.02 1.154l-.02.022a.88.88 0 01-1.22 0l-3.747-3.609c-2.686 1.763-6.377 1.496-8.76-.799a6.477 6.477 0 010-9.402c2.696-2.596 7.066-2.596 9.761 0z"
			/>
		</svg>
	)
}
